import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function LoginPopup(prop : any) {
    const {setLoginPopUp} = prop;
    const navigate = useNavigate();
    return (
        <div className='popup-wrp popup-show fade noti-popup'>
            <div className="pop pop-content">
                <div className="pop-head">
                    <h5>Notification</h5>
                    <div className="pop-close" onClick={()=> setLoginPopUp(false)}></div>
                </div>
                <div className="pop-body">
                    <p>Please log in or sign up to play the game. </p>
                    <div className="d-flex signup-login-btn">
                        <div className="login" onClick={()=>  {navigate('/login'); setLoginPopUp(false);}}>Login</div>
                        <div className="signup">Signup</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
