import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import FooterComponent from "../components/FooterComponent";
import { useNavigate } from "react-router-dom";
import { USER_API } from "../common/common";
import { getApi, notifyError, notifyMessage, postApi } from "../service";
import { useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { ImageUpload, InputGroup } from "./DepositWithdraw";

export default function Deposit(this: any) {
  const navigate = useNavigate();

  // const DD = useSelector((e: any) => e.domainDetails);
  const HeaderData = useSelector((e: any) => e.Header);
  const [activeTab, setActiveTab] = useState("Deposit");
  const [depositTab, setDepositTab] = useState("");
  const [depositChannelTab, setDepositChannelTab] = useState("");
  const [depositAmountTab, setDepositAmountTab] = useState(0);
  const [depositTotalAmountTab, setDepositTotalAmountTab] = useState(0);
  const [withdrawTab, setWithdrawTab] = useState("");
  const [withdrawAmountTab, setWithdrawAmountTab] = useState(0);
  const [withdrawTotalAmountTab, setWithdrawTotalAmountTab] = useState(0);
  const [methods, setMethods] = useState<any>([]);
  const [methodDetail, setMethodDetail] = useState<any>(null);
  const [methodDetailWd, setMethodDetailWd] = useState<any>(null);
  const balanceData = useSelector((e: any) => e.balance);
  const [balance, setBalance] = useState(balanceData);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // console.log("userData : HeaderData :: ", HeaderData);

  useEffect(() => {
    document.documentElement.style.setProperty("--bodyBackGround", `#111111`);
    setBalance(balanceData);

    return () => {};
  }, [balanceData]);

  const [depositForm, setDepositForm] = useState({
    userName: HeaderData?.user_name,
    mobileNo: HeaderData?.mobileNumber || 0,
    transactionId: "",
    amount: depositTotalAmountTab.toString(),
    image: "",
    accountNo: methodDetail?.accountNo,
    bankId: methodDetail?._id,
  });

  useEffect(() => {
    setDepositForm({
      ...depositForm,
      amount: depositTotalAmountTab.toString(),
    });
  }, [depositTotalAmountTab]);

  useEffect(() => {
    setDepositForm({
      ...depositForm,
      accountNo: methodDetail?.accountNo,
      bankId: methodDetail?._id,
    });
  }, [methodDetail]);
  const getMethods = async () => {
    const finalData = {
      api: USER_API.GET_METHOD+ '?paymentType='+activeTab,
      value: {
        paymentType: 'Deposit'
      },
    };

    await getApi(finalData)
      .then(function (response: any) {
        console.log(response.data.data, "methods");

        setMethods(response.data.data.data);
      })
      .catch((err: any) => {
        debugger;
        console.log(err);
        notifyError(err.response.data.message);
        if (err.response.data.statusCode === 401) {
        }
      });
  };
  useEffect(() => {
    getMethods();
    return(()=>{})
  }, [activeTab]);
  const TRANSACTION_METHOD: any = {
    bKash: "../../images/extra-icon/bkash.png",
    Rocket: "../../images/extra-icon/rocket.png",
    Nagad: "../../images/extra-icon/nagad.png",
    "Ok Wallet": "../../images/extra-icon/ok-wallet.png",
    SureCash: "../../images/extra-icon/surecash.png",
    Upay: "../../images/extra-icon/upay.png",
    Tap: "../../images/extra-icon/tap.png",
    "USDT TRC20": "../../images/extra-icon/trc20.png",
    BTC: "../../images/extra-icon/btc.png",
    "Local Bank": "../../images/extra-icon/local-bank.png",
  };
  const Validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );

  // deposit
  const onDepositSubmit = async (e: any) => {
    e.preventDefault();
    if (Validator.current.allValid()) {
      const data = new FormData();
      data.append("userName", depositForm.userName);
      data.append("mobileNo", depositForm.mobileNo);
      data.append("transactionId", depositForm.transactionId);
      data.append("amount", depositForm.amount);
      data.append("image", depositForm.image);
      data.append("accountNo", depositForm.accountNo);
      data.append("bankId", depositForm.bankId);

      const finalData = {
        api: USER_API.ADD_DEPOSIT,
        value: data,
      };
      await postApi(finalData)
        .then(function (response: any) {
          console.log(response);
          notifyMessage(response.data.message);
          setDepositForm({
            userName: HeaderData?.user_name,
            mobileNo: HeaderData?.mobileNumber || 0,
            transactionId: "",
            amount: depositTotalAmountTab.toString(),
            image: "",
            accountNo: methodDetail?.accountNo,
            bankId: methodDetail?._id,
          });
          window.location.reload()
        })
        .catch((err: any) => {
          debugger;
          console.log(err);
          notifyError(err.response.data.message);
          if (err.response.data.statusCode === 401) {
          }
        });
    } else {
      Validator.current.showMessages();
      forceUpdate();
    }
  };
  const handleDepositForm = (e: any) => {
    setDepositForm({ ...depositForm, [e.target.name]: e.target.value });
  };
  const handleImageUpload = (value: any, name: string) => {
    const file = value.target.files[0];
    setDepositForm({ ...depositForm, [name]: file });
  };

  // Withdraw
  const [withdrawForm, setWithdrawForm] = useState({
    accountNo: "",
    holderName: "",
    ifscCode: "",
    bankName: "",
    mobileNo: 0,
    amount: "",
    userName: "",
    descrpitions: "",
  });
  useEffect(() => {
    setWithdrawForm({
      ...withdrawForm,
      amount: withdrawTotalAmountTab.toString(),
    });
  }, [withdrawTotalAmountTab]);
  const onWithdrawSubmit = async (e: any) => {
    e.preventDefault();
    // if (secondTabSelected === "bank" && BankValidator.current.allValid()) {
    const data = {
      type: methodDetailWd?.type,
      accountNo: withdrawForm.accountNo,
      holderName: withdrawForm.holderName,
      userName: withdrawForm.holderName,
      ifscCode: withdrawForm.ifscCode,
      bankName: withdrawForm.bankName,
      mobileNo: withdrawForm.mobileNo,
      amount: withdrawForm.amount,
      descrpitions: withdrawForm.descrpitions,
    };
    const finalData = {
      api: USER_API.ADD_WITHDRAW,
      value: data,
    };
    await postApi(finalData)
      .then(function (response: any) {
        console.log(response);
        notifyMessage(response.data.message);
        window.location.reload()
      })
      .catch((err: any) => {
        debugger;
        console.log(err);
        notifyError(err.response.data.message);
        if (err.response.data.statusCode === 401) {
        }
      });
    // } else if (
    //   (secondTabSelected === "upi" ||
    //     secondTabSelected === "google pay" ||
    //     secondTabSelected === "phone pay") &&
    //   UpiValidator.current.allValid()
    // ) {
    //   const data = {
    //     type: secondTabSelected,
    //     userName: withdrawForm.userName,
    //     mobileNo: withdrawForm.mobileNo,
    //     accountNo: withdrawForm.accountNo,
    //     amount: withdrawForm.amount,
    //   };
    //   const finalData = {
    //     api: USER_API.ADD_WITHDRAW,
    //     value: data,
    //   };
    //   await postApi(finalData)
    //     .then(function (response: any) {
    //       console.log(response);
    //       notifyMessage(response.data.message);
    //     })
    //     .catch((err: any) => {
    //       debugger;
    //       console.log(err);
    //       notifyError(err.response.data.message);
    //       if (err.response.data.statusCode === 401) {
    //       }
    //     });
    // } else {
    //   if (secondTabSelected === "bank") {
    //     BankValidator.current.showMessages();
    //   } else {
    //     UpiValidator.current.showMessages();
    //   }
    //   forceUpdate();
    // }
  };
  const handleWithdrawForm = (e: any) => {
    setWithdrawForm({ ...withdrawForm, [e.target.name]: e.target.value });
  };
  const BankValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  return (
    <>
      <div className="mob-header">
        <div className="back" onClick={() => navigate("/")}>
          <img src="../../images/extra-icon/angle-left-white.png" height={22} />
        </div>
        <div className="center">
          <h5>Funds</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          {/* <FontAwesomeIcon icon={faMessage} /> */}
          <span>Help</span>
        </div>
      </div>
      <div className="tab-deposit-withdraw">
        <div className="btn-grp">
          <div
            className={`inv-btn ${activeTab === "Deposit" ? "active1" : ""}`}
            id="d-btn"
            onClick={() => {
              setActiveTab("Deposit");
            }}
          >
            Deposit
          </div>
          <div
            className={`inv-btn ${activeTab === "Withdrawal" ? "active1" : ""}`}
            id="w-btn"
            onClick={() => {
              setActiveTab("Withdrawal");
            }}
          >
            Withdraw
          </div>
          <div
            className={`btn-stick ${activeTab === "Withdrawal" ? "active" : ""}`}
            id="btn-stick"
          ></div>
        </div>
      </div>
      <div className="promotion-stick">
        <div className="prom-left">
          <img src="../../images/extra-icon/promotion.png" /> Select Promotion
        </div>
        <div className="prom-right">
          <select>
            <option>Extra 1.5% Deposit Bonus</option>
          </select>
        </div>
      </div>
      {activeTab === "Deposit" && (
        <>
          <div className="pm-box">
            <div className="box-title">
              <span>Payment Method</span>
            </div>
            <div className="pm-grid">
              {methods?.map((item: any) => {
                return (
                  <>
                    <div
                      className={depositTab === item.type ? "pm pm-brc" : "pm"}
                      onClick={() => {
                        setDepositTab(item.type);
                        setMethodDetail(item);
                      }}
                    >
                      <img
                        className="bank-icon"
                        src={TRANSACTION_METHOD[item.type]}
                        alt={item.type}
                      />
                      <br></br>
                      <span className="bank-name">{item.type}</span>
                      {depositTab === item.type && (
                        <img
                          className="bottom-tick"
                          src="../../images/extra-icon/bottom-tick.png"
                          alt=""
                        ></img>
                      )}
                      {/* <div className="ribbon">
                  <img src="../../images/extra-icon/ribbon.png" />
                  <span>+15%</span>
                </div> */}
                    </div>
                  </>
                );
              })}
            </div>
            <button className="pm-confirm-btn" id="pm-confirm-btn">
              {depositTab} Payment
            </button>
          </div>
          <div className="pm-box">
            <div className="box-title">
              <span>Deposit Channel</span>
            </div>

            <div className="pm-grid-btn">
              <button
                className={
                  depositChannelTab === "BK4-CASH" ? "dc pm-brc" : "dc"
                }
                onClick={() => setDepositChannelTab("BK4-CASH")}
              >
                BK4-CASH
              </button>
              <button
                className={depositChannelTab === "Bpay" ? "dc pm-brc" : "dc"}
                onClick={() => setDepositChannelTab("Bpay")}
              >
                Bpay
              </button>
            </div>
          </div>
          {methodDetail && (
            <div className="pm-box">
              <div className="box-title">
                <span>Deposit Detail</span>
              </div>
              <div className="pm-grid-btn">
                <div style={{ color: "white" }}>
                  Agent Cashout Number: {methodDetail?.accountNo}
                </div>
                <br />
                <div style={{ color: "white" }}>
                  Account Type: {methodDetail?.holderName}
                </div>
              </div>
              <div className="topTabs">
                <div className="first_tab_content">
                  <div className="tab_view">
                    <form action="" className="tab_form">
                      {/* <InputGroup type='text' placeholder='User/Login ID' name='userName' value={depositForm.userName} onChange={(e: any) => handleDepositForm(e)} errorValidation={Validator.current.message('userName', depositForm.userName, 'required')} /> */}
                      <InputGroup
                        type="number"
                        label="Your Deposit Number"
                        placeholder="Mobile"
                        name="mobileNo"
                        value={depositForm.mobileNo}
                        onChange={(e: any) => handleDepositForm(e)}
                        errorValidation={Validator.current.message(
                          "mobileNo",
                          depositForm.mobileNo,
                          "required|phone"
                        )}
                        // colorSchema={DD?.colorSchema}
                      />
                      <InputGroup
                        type="text"
                        placeholder="Transaction Id"
                        label="Transaction Id No"
                        name="transactionId"
                        value={depositForm.transactionId}
                        onChange={(e: any) => handleDepositForm(e)}
                        errorValidation={Validator.current.message(
                          "transactionId",
                          depositForm.transactionId,
                          "required"
                        )}
                        // colorSchema={DD?.colorSchema}
                      />
                      <InputGroup
                        type="text"
                        label="Amount"
                        placeholder="Amount"
                        name="amount"
                        value={depositForm.amount}
                        onChange={(e: any) => handleDepositForm(e)}
                        errorValidation={Validator.current.message(
                          "amount",
                          depositForm.amount,
                          "required|numeric"
                        )}
                        // colorSchema={DD?.colorSchema}
                      />

                      <ImageUpload
                        label="Your Payments Screenshort Upload"
                        filename={depositForm.image}
                        name="image"
                        setFileName={handleImageUpload}
                      />
                      {/* <div className="form_button">
                    <button className='cancel_btn form_btn' onClick={(e) => window.location.reload()}>Cancel</button>
                    <button
                      className="submit_btn form_btn"
                      onClick={(e) => onDepositSubmit(e)}
                    >
                      Submit
                    </button>
                  </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="pm-box">
            <div className="box-title amnt-ttl">
              <span>Amount</span>
              <span> ৳ 500 - ৳ 25,000</span>
            </div>

            <div className="am-grid-btn">
              <div
                className={
                  depositAmountTab === 100 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(100);
                  setDepositTotalAmountTab(depositTotalAmountTab + 100);
                }}
              >
                {depositAmountTab !==0 && "+"}100
              </div>
              <div
                className={
                  depositAmountTab === 500 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(500);
                  setDepositTotalAmountTab(depositTotalAmountTab + 500);
                }}
              >
                {depositAmountTab !==0 && "+"}500
              </div>
              <div
                className={
                  depositAmountTab === 1000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(1000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 1000);
                }}
              >
                {depositAmountTab !==0 && "+"}1,000
              </div>
              <div
                className={
                  depositAmountTab === 5000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(5000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 5000);
                }}
              >
                {depositAmountTab !==0 && "+"}5,000
              </div>
              <div
                className={
                  depositAmountTab === 10000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(10000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 10000);
                }}
              >
                {depositAmountTab !==0 && "+"}10,000
              </div>
              <div
                className={
                  depositAmountTab === 15000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(15000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 15000);
                }}
              >
                {depositAmountTab !==0 && "+"}15,000
              </div>
              <div
                className={
                  depositAmountTab === 20000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(20000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 20000);
                }}
              >
                {depositAmountTab !==0 && "+"}20,000
              </div>
              <div
                className={
                  depositAmountTab === 30000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setDepositAmountTab(30000);
                  setDepositTotalAmountTab(depositTotalAmountTab + 30000);
                }}
              >
                {depositAmountTab !==0 && "+"}30,000
              </div>
            </div>

            <div className="amount-list-bottom">
              <div id="currency">৳</div>
              <div id="total-deposit-amount">
                {depositTotalAmountTab ? depositTotalAmountTab : 0.0}
              </div>
            </div>

            <div className="notice">
              <div>
                <img
                  src="../../images/extra-icon/exclamation-white.png"
                  height={20}
                />
              </div>
              <div>
                Dear all member, to speed up your deposit process, kindly follow
                these steps :<br />
                1.verify the phone number you used for deposit.
                <br />
                2. Input the correct reference number.
                <br />
                3. Process only selected amount.
                <br />
                4. Attach the successful Slip.
                <br />
                Reminder:
                <br />
                Do not save the phone number displayed on Baji website on your
                device to avoid losing money.
              </div>
            </div>
          </div>
          <button id="submit-pm-btn" onClick={(e) => onDepositSubmit(e)}>
            Submit
          </button>
        </>
      )}

      {/* Withdraw */}
      {activeTab === "Withdrawal" && (
        <>
          <div className="withdrawal-blnc">
            <div className="main-wallet-txt">Main wallet</div>
            <div className="main-balance">{balance ? balance : 0.0}</div>
          </div>
          {false && (
            <div className="required-board">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img src="../../images/extra-icon/exclamation-white.png" />
                    </td>
                    <td>
                      Please complete the verification below before you proceed
                      with the withdrawal request.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ padding: "15px" }}>
                <div className="req-ttl req-ttl2">Contact Info</div>
                <span className="req2">Phone Number</span>
                <span className="req2">Email</span>{" "}
              </div>
            </div>
          )}
          <div className="pm-box">
            <div className="box-title">
              <span>Payment Method</span>
            </div>
            <div className="pm-grid">
              {methods.map((item: any) => {
                return (
                  <>
                    <div
                      className={withdrawTab === item.type ? "pm pm-brc" : "pm"}
                      onClick={() => {
                        setWithdrawTab(item.type);
                        setMethodDetailWd(item);
                      }}
                    >
                      <img
                        className="bank-icon"
                        src={TRANSACTION_METHOD[item.type]}
                        alt={item.type}
                      />
                      <br></br>
                      <span className="bank-name">{item.type}</span>
                      {withdrawTab === item.type && (
                        <img
                          className="bottom-tick"
                          src="../../images/extra-icon/bottom-tick.png"
                          alt=""
                        ></img>
                      )}
                    </div>
                  </>
                );
              })}
              {/* <div
                className={withdrawTab === "bKash" ? "pm pm-brc" : "pm"}
                onClick={() => {
                  setWithdrawTab("bKash");
                }}
              >
                <img
                  className="bank-icon"
                  src="../../images/extra-icon/bkash.png"
                />
                <br></br>
                <span className="bank-name">bKash</span>
                {withdrawTab === "bKash" && (
                  <img
                    className="bottom-tick"
                    src="../../images/extra-icon/bottom-tick.png"
                  ></img>
                )}
                <div className="ribbon">
                  <img src="../../images/extra-icon/ribbon.png" />
                  <span>+15%</span>
                </div>
              </div> */}
            </div>
            <button className="pm-confirm-btn" id="pm-confirm-btn">
              Payment
            </button>
          </div>
          {methodDetailWd && (
            <div className="pm-box">
              <div className="box-title">
                <span>Withdraw Detail</span>
              </div>
              <div className="topTabs">
                <div className="first_tab_content">
                  <div className="tab_view">
                    <form action="" className="tab_form">
                      <div className="side_by_side">
                        <InputGroup
                          type="number"
                          placeholder="Please Enter Bank Account No*"
                          label="Personal Account Number :"
                          name="accountNo"
                          value={withdrawForm.accountNo}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation={BankValidator.current.message(
                            "accountNo",
                            withdrawForm.accountNo,
                            "required"
                          )}
                          // colorSchema={DD?.colorSchema}
                        />
                        <InputGroup
                          type="text"
                          placeholder="Please Enter Account Name *"
                          label="Account Name."
                          name="holderName"
                          value={withdrawForm.holderName}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation=""
                          // colorSchema={DD?.colorSchema}
                        />
                        {/* <InputGroup
                          type="text"
                          placeholder="Please Enter Branch IFSC *"
                          label="Branch IFSC Code"
                          name="ifscCode"
                          value={withdrawForm.ifscCode}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation=""
                          // colorSchema={DD?.colorSchema}
                        />
                        <InputGroup
                          type="text"
                          placeholder="Please Enter Bank Name *"
                          label="Bank Name"
                          name="bankName"
                          value={withdrawForm.bankName}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation=""
                          // colorSchema={DD?.colorSchema}
                        />
                        <InputGroup
                          type="number"
                          placeholder="Please Enter Mobile No *"
                          label="Mobile No"
                          name="mobileNo"
                          value={withdrawForm.mobileNo}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation={BankValidator.current.message(
                            "mobileNo",
                            withdrawForm.mobileNo,
                            "required"
                          )}
                          // colorSchema={DD?.colorSchema}
                        /> */}
                        <InputGroup
                          type="number"
                          placeholder="Please Enter Withdrawal Amount *"
                          label="Amount"
                          // amount={balance}
                          name="amount"
                          value={withdrawForm.amount}
                          onChange={(e: any) => handleWithdrawForm(e)}
                          errorValidation={BankValidator.current.message(
                            "amount",
                            withdrawForm.amount,
                            "required|numeric"
                          )}
                          // colorSchema={DD?.colorSchema}
                        />
                      </div>
                      {/* <div className="text_area">
                      <label htmlFor="description">Description</label>
                      <textarea
                        name="descrpitions"
                        value={withdrawForm.descrpitions}
                        onChange={(e) => handleWithdrawForm(e)}
                        id="description"
                        placeholder="Description"

                      ></textarea>
                    </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="pm-box">
            <div className="box-title amnt-ttl">
              <span>Amount</span>
              <span> ৳ 500 - ৳ 25,000</span>
            </div>

            <div className="am-grid-btn">
              <div
                className={
                  withdrawAmountTab === 100 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(100);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 100);
                }}
              >
                {withdrawAmountTab !== 0 && "+"} 
                100
              </div>
              <div
                className={
                  withdrawAmountTab === 500 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(500);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 500);
                }}
              >
                {depositAmountTab !==0 && "+"}500
              </div>
              <div
                className={
                  withdrawAmountTab === 1000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(1000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 1000);
                }}
              >
                {depositAmountTab !==0 && "+"}1,000
              </div>
              <div
                className={
                  withdrawAmountTab === 5000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(5000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 5000);
                }}
              >
                {depositAmountTab !==0 && "+"}5,000
              </div>
              <div
                className={
                  withdrawAmountTab === 10000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(10000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 10000);
                }}
              >
                {depositAmountTab !==0 && "+"}10,000
              </div>
              <div
                className={
                  withdrawAmountTab === 15000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(15000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 15000);
                }}
              >
                {depositAmountTab !==0 && "+"}15,000
              </div>
              <div
                className={
                  withdrawAmountTab === 20000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(20000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 20000);
                }}
              >
                {depositAmountTab !==0 && "+"}20,000
              </div>
              <div
                className={
                  withdrawAmountTab === 30000 ? "am-btn pm-brc" : "am-btn"
                }
                onClick={() => {
                  setWithdrawAmountTab(30000);
                  setWithdrawTotalAmountTab(withdrawTotalAmountTab + 30000);
                }}
              >
                {depositAmountTab !==0 && "+"}30,000
              </div>
            </div>

            <div className="amount-list-bottom">
              <div id="currency">৳</div>
              <div id="total-deposit-amount">
                {withdrawTotalAmountTab ? withdrawTotalAmountTab : 0.0}
              </div>
            </div>

            <div className="notice">
              <div>
                <img
                  src="../../images/extra-icon/exclamation-white.png"
                  height={20}
                />
              </div>
              <div>
                Note: Double check the recipient's account and processing
                amount. Do not share your account details with anyone to avoid
                losing money.
              </div>
            </div>
          </div>
          <button id="submit-pm-btn" onClick={(e) => onWithdrawSubmit(e)}>
            Submit
          </button>
        </>
      )}
      <br />
      <br />
      <br />
      <br />
      <FooterComponent />
    </>
  );
}
